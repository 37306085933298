.item_selected {
    background-color: #7CA8DF;
    border: 1px solid #7CA8DF;
    color: white;
}


.item_no_selected {
    border: solid 1px #7CA8DF;
    color: #7CA8DF;
    transition: 0.2s;
}

.item_no_selected:hover {
    transition: 0.2s;
    background-color: #adcfd4;
}

.common {
    min-width: 55px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.droitpartage{
    min-width: 55px;
    min-height: 35px;
    text-align: center;
    line-height: 35px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    padding: 0px 10px 0px 10px;
}

.item_large {
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
    /*line-height: 35px;*/
    min-height: 35px;
}

.item_ctn {
    display: flex;
    flex-direction: row;
    gap: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
}


.item_static {
    background-color: #7CA8DF;
    border: 1px solid #7CA8DF;
    color: white;
}


.item_static:hover {
    transition: 0.2s;
    background-color: #7CA8DF;
}
