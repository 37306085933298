.cardRow {
  display: grid;
  width: 90%;
  margin-left: 5%;
  grid-template-columns: 1fr 1fr;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.cardSimulateur {
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  background-color: white;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  min-width: 400px;
  width: 90%;
}

.cardDMTG {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  min-width: 400px;
}

.cardDonateurs {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 30px;
  margin-left: 30px;
  padding: 10px;
  border-radius: 0px 0px 4px 4px;
  background-color: white;
}

.cardSubTitle {
    font-size: 16px;
    font-style: italic;
    margin-left: 5%;
    margin-right: 10%;
    border: 1px solid lightblue;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
  }
  .cardFlexContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 15px;
    margin-left: 15px;
    padding: 10px;
    border-radius: 4px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    max-width: 450px;
    min-width: auto;
  }


.cardTitleDonateur {
    font-weight: bolder;
    font-size: 20px;
    color: white;
    background-color: rgb(157 114 169);
    text-align: center;
    line-height: 1.4em;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 0px;
    border-radius: 4px 4px 0px 0px;
  }
  .cardTitleDonataire {
    font-weight: bolder;
    font-size: 17px;
    color: white;
    background-color: #71add9;
    text-align: center;
    line-height: 1.4em;
    padding: 10px;
    margin-top: -10px;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 10px;
    border-radius: 4px 4px 0px 0px;
  }

@media screen and (max-width: 800px) {
  .cardRow {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .cardSimulateur {
    min-width: 0px;
    width: 100%;
  }

  .cardDMTG {
    min-width: 0px;
  }
  .cardDonateurs {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 10px;
    border-radius: 0px 0px 4px 4px;
    background-color: white;
  }
}
